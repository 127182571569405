import { useMemo } from 'react';

import type {
  ApolloQueryResult,
  DocumentNode, MutationTuple, ObservableQueryFields, OperationVariables, QueryResult, QueryTuple,
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

import type { Literal } from '../services/guards';
import { isLiteral } from '../services/guards';
import type { Nullable } from '../services/object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Parser = (value: any) => unknown;
type Path = [path: string, scalar: string];
type ScalarParsers = Partial<Record<string, Parser>>;

const parsePathFragment = (pathFragment: Nullable<string>) => {
  if (isNil(pathFragment)) {
    return {
      pathFragment,
      array: false,
    };
  }

  if (pathFragment.includes('[]')) {
    return {
      pathFragment: pathFragment.replace('[]', ''),
      array: true,
    };
  }

  return {
    pathFragment,
    array: false,
  };
};

export const traverse = (
  currentValue: unknown,
  remainingPath: string[],
  parser: Parser,
): unknown => {
  const [unparsedCurrentPath, ...rest] = remainingPath;
  const currentPart = parsePathFragment(unparsedCurrentPath).pathFragment;

  if (isNil(currentPart)) {
    return parser(currentValue);
  }

  if (isLiteral(currentValue)) {
    if (currentPart in currentValue) {
      return {
        ...currentValue,
        [currentPart]: traverse(currentValue[currentPart], rest, parser),
      };
    }

    return currentValue;
  }

  if (isArray(currentValue)) {
    return currentValue.map((value) => traverse(value, remainingPath, parser));
  }

  return currentValue;
};

const mapScalars = <T extends Literal, Parsers extends ScalarParsers>(
  originalObject: T, paths: Path[], scalarParsers: Parsers,
) => paths
    .reduce((obj, [path, scalarType]) => {
      const scalarParser = scalarParsers[scalarType];

      // no parser so keep the scalar as-is
      if (isNil(scalarParser)) {
        return obj;
      }

      // this cast would be unsafe if paths to scalars were wrong.
      // to make it super safe, an additional guard per scalar parser could be provided
      // which would check if the passed scalar is of correct type.
      return traverse(obj, path.split('.'), scalarParser) as T;
    }, originalObject);

// utility function for parsing variables based on the provided document
// most of functions which apollo requires variables in, have signature like
// someApolloFunction({variables: ..., otherField:...})
// an exception is 'refetch' which requires _just_ variables, which makes it bit awakward to use
// (notice wrapped refetches in hooks below)
export const parseVariables = <Parsers extends ScalarParsers>(
  document: DocumentNode,
  scalarPaths: Record<string, Path[]>,
  scalarParsers: Parsers,
) => <T extends { variables?: unknown } | undefined>(
    opts: T,
  ): T => {
  const { variables, ...others } = opts ?? {};
  const operationName = getOperationName(document);

  // check if given operation (query, mutation) has defined scalar paths
  // if not, don't risk it and just noop. (this could throw an error instead)
  if (isNil(operationName) || !(operationName in scalarPaths)) {
    return opts;
  }

  const paths = scalarPaths[operationName];

  return {
    ...others,

    // since each path in `paths` points to 1 scalar,
    // this goes through each path at a time and replaces given scalar
    // (unless no scalarParser is provided)
    variables: !isNil(variables) && isLiteral(variables)
      ? mapScalars(variables, paths, scalarParsers)
      : variables,
  } as T;
};

type DefaultArgType = QueryResult extends QueryResult<infer T> ? T : never;
// these hook wrappers are meant to only wrap functions which require variables.
export const useQueryVariables = <T extends QueryResult<DefaultArgType, DefaultArgType>>(
  parser: ReturnType<typeof parseVariables>,
  queryHookResult: T,
): T => {
  type QueryFields = T extends QueryResult<infer U, infer W> ? ObservableQueryFields<U, W> : never;

  const { refetch, fetchMore, ...others } = queryHookResult;

  const wrappedRefetch = useMemo(() => {
    const wrapper: QueryFields['refetch'] = async (variables) => refetch(parser({ variables }).variables);
    return wrapper;
  }, [refetch, parser]);

  const wrappedFetchMore = useMemo(() => {
    // this (and other fetchMore wrappers) would ideally be
    // const wrapper: QueryFields['fetchMore'] = ...
    // but that makes options get `any`
    const wrapper: QueryFields['fetchMore'] = async (
      options: Parameters<QueryFields['fetchMore']>[number],
    ): Promise<ApolloQueryResult<DefaultArgType>> => fetchMore(parser(options));

    return wrapper;
  }, [fetchMore, parser]);

  return { ...others, refetch: wrappedRefetch, fetchMore: wrappedFetchMore } as T;
};

export const useLazyQueryVariables = <T, U extends OperationVariables>(
  parser: ReturnType<typeof parseVariables>,
  lazyQueryHookResult: QueryTuple<T, U>,
): QueryTuple<T, U> => {
  const [load, { fetchMore, refetch, ...others }] = lazyQueryHookResult;

  type QueryFields = QueryTuple<T, U>;

  const wrappedLoad = useMemo(() => {
    const wrapper: QueryFields[0] = async (options) => load(parser(options));
    return wrapper;
  }, [load, parser]);

  const wrappedRefetch = useMemo(() => {
    const wrapper: QueryFields[1]['refetch'] = async (variables) => refetch(parser({ variables }).variables);
    return wrapper;
  }, [refetch, parser]);

  const wrappedFetchMore = useMemo(() => {
    const wrapper = async (options: Parameters<NonNullable<QueryFields[1]['fetchMore']>>[number]) => {
      const queryResult = await fetchMore(parser(options));
      return queryResult;
    };

    return wrapper;
  }, [fetchMore, parser]);

  return [
    wrappedLoad,
    { fetchMore: wrappedFetchMore, refetch: wrappedRefetch, ...others },
  ] as QueryTuple<T, U>;
};

export const useMutationVariables = <T, U>(
  parser: ReturnType<typeof parseVariables>,
  mutationHookResult: MutationTuple<T, U>,
): MutationTuple<T, U> => {
  const [mutate, ...others] = mutationHookResult;

  const wrappedMutate = useMemo(() => {
    const wrapper: MutationTuple<T, U>[0] = async (options) => mutate(parser(options));
    return wrapper;
  }, [mutate, parser]);

  return [wrappedMutate, ...others];
};

export default parseVariables;
