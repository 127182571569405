import nationalities from 'i18n-nationality';
import en from 'i18n-nationality/langs/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale as setYupLocale } from 'yup';
import type { LocaleObject } from 'yup/lib/locale';

import yupLocale from '@virtuslab/nfs-shared/src/services/i18n/yupLocale';

import enGB from './translations/en-GB';

setYupLocale(yupLocale as LocaleObject);

const defaultNS = 'translation';
void i18n
  .use(initReactI18next)
  .init({
    resources: { 'en-GB': { [defaultNS]: enGB } },
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    defaultNS,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

nationalities.registerLocale(en);

export {
  defaultNS,
};
export default i18n;
