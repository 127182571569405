export enum BonusType {
  EmployeeReferralBonus = 'EmployeeReferralBonus',
  AppreciationBonus = 'AppreciationBonus',
  LanguageLearningBonus = 'LanguageLearningBonus',
  RemoteWorkEquipment = 'RemoteWorkEquipment',
  TrainingPackage = 'TrainingPackage',
  OtherBonus = 'OtherBonus',
}

export const ExtendedAbsenceTypeInput = {
  ANNUALLEAVE: 'AnnualLeave',
  ANNUALLEAVE_HOURLY: 'AnnualLeaveHourly',
  CAREGIVINGLEAVE: 'CaregivingLeave',
  CHILDCARELEAVE: 'ChildCareLeave',
  DUETOFORCEMAJEURELEAVE: 'DueToForceMajeureLeave',
  FLEXTIMELEAVE: 'FlextimeLeave',
  MATERNITYLEAVE: 'MaternityLeave',
  OUTOFOFFICE: 'OutOfOffice',
  PAIDLEAVE: 'PaidLeave',
  SICKLEAVE: 'SickLeave',
  SPECIALLEAVE: 'SpecialLeave',
  UNPAIDLEAVE: 'UnpaidLeave',
} as const;

export type ExtendedAbsenceTypeInputType = typeof ExtendedAbsenceTypeInput[keyof typeof ExtendedAbsenceTypeInput];

export default {};
